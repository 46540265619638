import { ChainDate } from './date-chain';

class GqlFilter {
  /**
   *
   * @param filters array of <operator>, <value> pairs like [ ['gte', 5], ['lte', 10] ]
   */
  constructor(filters = {}) {
    this.filters = filters;
  }

  create() {
    return Object.entries(this.filters).reduce((acc, [key, value]) => {
      if (!key || !value) return acc;
      acc[key] = value;
      return acc;
    }, {});
  }
}

export class DateTimeFilter extends GqlFilter {
  constructor(operator, value) {
    if (!value) return super(operator, undefined);
    const [day, month, year] =
      value instanceof Date
        ? [value.getDate(), value.getMonth() + 1, value.getFullYear()]
        : value.split(/[^0-9]/);
    const date = new Date([year, month, day].join('/'));
    const isValid = !isNaN(date.getTime());

    if (operator === 'equals') {
      super({
        gte: new ChainDate(date)
          .setHours(0)
          .setMinutes(0)
          .setSeconds(0)
          .native.toISOString(),
        lte: new ChainDate(date)
          .setHours(23)
          .setMinutes(59)
          .setSeconds(59)
          .native.toISOString(),
      });
    } else if (operator === 'gt') {
      super({
        gt: new ChainDate(date)
          .setHours(23)
          .setMinutes(59)
          .setSeconds(59)
          .native.toISOString(),
      });
    } else if (operator === 'lt') {
      super({
        lt: new ChainDate(date)
          .setHours(0)
          .setMinutes(0)
          .setSeconds(0)
          .native.toISOString(),
      });
    } else {
      super({
        [operator]: isValid ? date.toISOString() : undefined,
      });
    }
  }
}

export class StringFilter extends GqlFilter {
  constructor(operator = 'contains', value, meta = { mode: 'INSENSITIVE' }) {
    super(value ? { [operator]: value, ...meta } : {});
  }
}

export class StringListFilter extends GqlFilter {
  constructor(operator = 'has', value) {
    super(value ? { [operator]: value } : {});
  }
}

export class IntFloatFilter extends GqlFilter {
  constructor(operator, value) {
    const number = Number(value);
    const isValid = !isNaN(number);
    super({ [operator]: isValid ? number : undefined });
  }
}

export class BooleanFilter extends GqlFilter {
  constructor(operator, value) {
    console.log({ operator, value });
    super();
  }
}
